import s from './Step3.module.scss';
import { Input } from '@/shared/ui/Input/Input';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { Step3Query, useStep3Mutation } from '../../api/registrationApi';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
    resetRegistrationData,
    setRegistrationData,
} from '@/features/RegistrationForm/model/slice/registrationSlice';
import { Step3Data } from '../../model/types/step3';
import { step3Schema } from '../../lib/validationSchema/Step3Schema';
import { Button } from '@/shared/ui/Button/Button';
import {
    prevStep,
    resetRegistrationStepperData,
} from '@/widgets/RegistrationStepper';
import { Link, useNavigate } from 'react-router-dom';
import { InputPassword } from '@/shared/ui/InputPassword/InputPassword';
import { useFormError } from '@/shared/lib/hooks/useFormError';
import { useScreenDetector } from '@/shared/lib/hooks/useScreenDetector';
import { PasswordRequirementsList } from '@/shared/ui/PasswordRequirementsList/PasswordRequirementsList';
import { Checkbox } from '@/shared/ui/Checkbox/Checkbox';
import politicNDA from '@/shared/files/politicNDA.pdf';
import userAccess from '@/shared/files/userAccess.pdf';

export const Step3 = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isMobile } = useScreenDetector();
    const [triggerStep3, { isLoading, isSuccess, error }] = useStep3Mutation();

    const [isUserSuccess, setIsUserSuccess] = useState(false);

    const { registrationToken } = useAppSelector((state) => state.registration);

    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitted, touchedFields },
        setError,
    } = useForm<Step3Data>({
        mode: 'onTouched',
        resolver: yupResolver(step3Schema),
        criteriaMode: 'all',
        defaultValues: {
            login: '',
            password: '',
            confirmPassword: '',
        },
    });

    const onSubmit: SubmitHandler<Step3Data> = (data) => {
        triggerStep3({
            registrationToken,
            login: data.login,
            password: data.password,
        }).then(() => {
            dispatch(
                setRegistrationData({
                    login: data.login,
                }),
            );
        });
    };

    useEffect(() => {
        if (isSuccess) {
            dispatch(resetRegistrationData());
            dispatch(resetRegistrationStepperData());
            navigate('/success');
        }
    }, [isSuccess]);

    useFormError<Step3Query>(error, setError);

    const handleChangeUserSuccess = () => {
        setIsUserSuccess((prev) => !prev);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={s.inputs}>
                <Controller
                    name="login"
                    control={control}
                    render={({ field: { value, onChange, onBlur, name } }) => (
                        <Input
                            value={value}
                            onChange={onChange}
                            label={'Логин*'}
                            placeholder={'username123'}
                            error={errors.login?.message}
                            isSubmitted={isSubmitted}
                            onBlur={onBlur}
                            name={name}
                        />
                    )}
                />

                <Controller
                    name="password"
                    control={control}
                    render={({ field: { value, onChange, onBlur, name } }) => (
                        <InputPassword
                            value={value}
                            onChange={onChange}
                            label={'Пароль*'}
                            placeholder={'Введите пароль'}
                            error={errors.password?.message}
                            onBlur={onBlur}
                            name={name}
                        />
                    )}
                />
                <Controller
                    name="confirmPassword"
                    control={control}
                    render={({ field: { value, onChange, onBlur, name } }) => (
                        <InputPassword
                            value={value}
                            onChange={onChange}
                            label={'Повторите пароль*'}
                            placeholder={'Введите пароль'}
                            error={errors.confirmPassword?.message}
                            onBlur={onBlur}
                            name={name}
                        />
                    )}
                />
            </div>
            <PasswordRequirementsList
                error={errors.password}
                isTouched={touchedFields.password}
            />

            <div className={s.user_success}>
                <Checkbox
                    className={s.checkbox}
                    checked={isUserSuccess}
                    onChange={handleChangeUserSuccess}
                />
                <p className={s.checkboxText}>
                    Согласие с{' '}
                    <Link target="_blank" to={userAccess}>
                        политикой обработки персональных данных
                    </Link>{' '}
                    и{' '}
                    <Link target="_blank" to={politicNDA}>
                        политикой конфиденциальности
                    </Link>
                </p>
            </div>

            <div className={s.actions}>
                <Button
                    onClick={() => dispatch(prevStep())}
                    variant={'outline'}
                    className={s.prev}
                    size={isMobile ? 's' : 'm'}
                >
                    Назад
                </Button>
                <Button
                    type={'submit'}
                    className={s.next}
                    disabled={isLoading || !isUserSuccess}
                    size={isMobile ? 's' : 'm'}
                    onClick={handleSubmit(onSubmit)}
                >
                    Зарегистрироваться
                </Button>
            </div>
        </form>
    );
};
